import React, { useEffect } from 'react';
import { Get } from 'api';
import { useStore } from 'store/store';

import Home from 'features/Home/Home';
import Loading from 'components/Loading/Loading';
import MultiComparison from 'features/MultiComparison/MultiComparison';
import MultiResults from 'features/MultiResults/MultiResults';
import Overlay from 'components/Overlay/Overlay';
import ReportDownload from 'features/ReportDownload/ReportDownload';
import SingleComparison from 'features/SingleComparison/SingleComparison';
import SingleResults from 'features/SingleResults/SingleResults';
import TotalCalculation from 'features/TotalCalculation/TotalCalculation';
import WidthWarning from 'components/WidthWarning/WidthWarning';
import './Main.scss';

/**
 * Main panel of the app. Where most of the action is.
 */
function Main() {
  const [state, dispatch] = useStore();

  // Fetch the list of Stirling ULT models.
  useEffect(() => {
    dispatch('ACTIVATE_LOADING_SCREEN');
    dispatch('LOAD_PROJECTS');

    Get('models/')
      .then((response) => {
        if (process.env.REACT_APP_DEBUG_LOGGING) console.log(response);
        return response.data;
      })
      .then((data) => {
        dispatch('ADD_COMPETITOR_MODELS', data.competitors);
        dispatch('ADD_FREEZER_MODELS', data.freezers);
        dispatch('DEACTIVATE_LOADING_SCREEN');
      })
      .catch((error) => {
        console.log('Fatal fetch error in Home');
        console.error(error);
      });
  }, [dispatch]);

  return (
    <div className="main">
      <Home />
      <MultiComparison />
      <MultiResults />
      <SingleComparison />
      <SingleResults />
      <ReportDownload />
      <TotalCalculation />
      <Loading />
      <WidthWarning />
      <Overlay
        visible={
          state.saveModalActive ||
          state.renameModalActive ||
          state.projectPanelVisible
        }
      />
    </div>
  );
}

export default Main;
