import React from 'react';

import './TextBox.scss';

interface TextBoxProps {
  label?: string;
  name: string;
  maxLength?: number;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
}

/**
 * Basic text box form, passing back the new value whenever it changes. Due to the
 * way change works here, note that it passes back a new value on every keypress.
 */
function TextBox(props: TextBoxProps) {
  return props.label ? (
    <label className="textbox-wrap typo-form-header">
      {props.label}
      <input
        className="textbox"
        type="text"
        name={props.name}
        placeholder={props.placeholder}
        maxLength={props.maxLength ? props.maxLength : undefined}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        value={props.value}
      />
    </label>
  ) : (
    <input
      className="textbox"
      type="text"
      name={props.name}
      placeholder={props.placeholder}
      maxLength={props.maxLength ? props.maxLength : undefined}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      value={props.value}
    />
  );
}

export default TextBox;
