import React, { PropsWithChildren } from 'react';

import './ComparisonForm.scss';

/**
 * Wrapper component for the various forms to inject shared styling.
 * There are some classes you can add to your children elements in order to
 * pick up some theming.
 * `section` - Wrapper that adds tighter spacing, more closely associating its fields.
 * `typo-form-header` - Font styling class that matches the form control labels.
 * `animate-height` - Transitions the height of the element. Good for elements that may be
 *   enabled and disabled.
 * `animate-visibility` - Transitions the opacity of the element. Good for elements that may
 *   be enabled and disabled.
 */
function ComparisonForm(props: PropsWithChildren) {
  return <div className="comparison-form">{props.children}</div>;
}

export default ComparisonForm;
