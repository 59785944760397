import React from 'react';

interface WeKnowColdProps {
  iconTitle: string;
}

const WeKnowCold = (props: WeKnowColdProps) => {
  return (
    <svg
      viewBox="0 0 74 60"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>We Know Cold</title>
      <g clipPath="url(#clip0_1002_1599)">
        <path
          d="M32.115 0H36.0121L30.6832 18.2403H27.0476L25.0181 9.22598L24.2586 5.61527L23.4618 9.22598L21.4323 18.2403H17.7469L12.418 0H16.4396L18.7927 9.12638L19.6643 12.7869L20.4113 9.12638L22.4034 0H26.1511L28.1432 9.12638L28.8903 12.7869L29.7618 9.12638L32.115 0Z"
          fill="#004876"
        />
        <path
          d="M38.6009 10.2469H44.4652C44.266 8.40423 43.1828 7.32102 41.5393 7.32102C39.8958 7.32102 38.7752 8.42913 38.5885 10.2469M48.2004 12.6997H38.6134C38.7628 14.6918 39.9207 15.8747 41.7136 15.8747C42.9711 15.8747 43.8427 15.3144 44.2785 14.1565L47.8643 14.4179C46.9678 17.2816 44.764 18.6761 41.7883 18.6761C37.4057 18.6761 34.8906 15.6256 34.8906 11.5792C34.8906 7.53268 37.3559 4.68147 41.4023 4.68147C45.4488 4.68147 48.188 7.44553 48.188 12.0896V12.6997H48.2004Z"
          fill="#004876"
        />
        <path
          d="M20.7993 30.2801L26.0535 38.2984H21.7704L19.2305 34.1772L18.3091 32.4839L16.9271 33.9158V38.2984H13.3164V20.0581H16.9271V27.3542L16.8275 30.4047L18.471 28.3503L21.4094 25.0757H25.9414L20.7993 30.2801Z"
          fill="#004876"
        />
        <path
          d="M39.1856 29.4335V38.2984H35.5749V30.2552C35.5749 28.8732 34.9399 28.0017 33.6575 28.0017C32.126 28.0017 31.0677 29.2592 31.0677 31.2264V38.2984H27.457V25.0757H30.993L31.0428 26.8686C31.9144 25.4368 33.1719 24.7396 34.8652 24.7396C37.6044 24.7396 39.1981 26.5574 39.1981 29.4335"
          fill="#004876"
        />
        <path
          d="M51.079 31.7369C51.079 29.1969 49.8215 27.5659 47.8044 27.5659C45.7874 27.5659 44.5299 29.2094 44.5299 31.7369C44.5299 34.2644 45.7874 35.9079 47.8044 35.9079C49.8215 35.9079 51.079 34.2644 51.079 31.7369ZM41.0312 31.7369C41.0312 27.7402 43.8202 24.7396 47.792 24.7396C51.7638 24.7396 54.5527 27.7402 54.5527 31.7369C54.5527 35.7336 51.7638 38.7342 47.792 38.7342C43.8202 38.7342 41.0312 35.7336 41.0312 31.7369Z"
          fill="#004876"
        />
        <path
          d="M70.2026 25.0757H74L70.0781 38.2984H66.6417L64.7491 30.94L64.4379 29.1969L64.1017 30.94L62.2092 38.2984H58.7728L54.8633 25.0757H58.6483L60.105 30.94L60.7276 34.2768L61.3377 30.94L62.8317 25.0757H66.0316L67.5132 30.8902L68.1233 34.2768L68.7458 30.94L70.2026 25.0757Z"
          fill="#004876"
        />
        <path
          d="M12.5938 52.9778C12.5938 48.7197 15.0341 45.9805 18.9436 45.9805C22.3302 45.9805 24.534 47.7983 25.0694 50.9234H21.5334C21.1474 49.4916 20.3007 48.7944 18.9934 48.7944C17.1507 48.7944 16.0675 50.276 16.0675 52.9654C16.0675 55.6547 17.1383 57.1363 18.9934 57.1363C20.2758 57.1363 21.1225 56.3893 21.5334 54.9575H25.0694C24.534 58.1075 22.2804 59.9502 18.9436 59.9502C15.0216 59.9502 12.5938 57.211 12.5938 52.9529"
          fill="#91C3B9"
        />
        <path
          d="M36.4501 52.9778C36.4501 50.4379 35.1925 48.8068 33.1755 48.8068C31.1585 48.8068 29.901 50.4503 29.901 52.9778C29.901 55.5053 31.1585 57.1488 33.1755 57.1488C35.1925 57.1488 36.4501 55.5053 36.4501 52.9778ZM26.4023 52.9778C26.4023 48.9811 29.1913 45.9805 33.1631 45.9805C37.1349 45.9805 39.9238 48.9811 39.9238 52.9778C39.9238 56.9745 37.1349 59.9751 33.1631 59.9751C29.1913 59.9751 26.4023 56.9745 26.4023 52.9778Z"
          fill="#91C3B9"
        />
        <path
          d="M45.3881 41.299H41.7773V59.5393H45.3881V41.299Z"
          fill="#91C3B9"
        />
        <path
          d="M56.6674 52.8782C56.6674 50.5001 55.4348 48.9811 53.6917 48.9811C51.9486 48.9811 50.7159 50.4628 50.7159 52.8782C50.7159 55.2936 51.9486 56.8002 53.6917 56.8002C55.4348 56.8002 56.6674 55.2687 56.6674 52.8782ZM56.4931 41.299H60.1038V59.5393H56.9039L56.7794 57.8958C55.883 59.2779 54.5757 59.9751 52.9322 59.9751C49.6078 59.9751 47.2422 57.1363 47.2422 52.8782C47.2422 48.6201 49.6452 45.9805 52.6458 45.9805C54.3391 45.9805 55.5966 46.5408 56.4931 47.6489V41.299Z"
          fill="#91C3B9"
        />
        <path d="M1.78537 0H0.851562V60H1.78537V0Z" fill="#CCDBE3" />
        <path
          d="M64.4362 41.299H61.9336V41.6601H62.9795V44.4366H63.3903V41.6601H64.4362V41.299ZM67.4493 41.299L66.5902 43.6896L66.4906 44.0008L66.3909 43.6896L65.5069 41.299H64.9342V44.4242H65.3077V41.7846L65.5069 42.2702L66.3162 44.4242H66.7022L67.4493 42.3449L67.6734 41.7846V42.3449V44.4242H68.0469V41.299H67.4742H67.4493Z"
          fill="#004876"
        />
      </g>
      <defs>
        <clipPath id="clip0_1002_1599">
          <rect
            width="73.148"
            height="60"
            fill="white"
            transform="translate(0.851562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WeKnowCold;
