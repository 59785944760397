import React, { useEffect, useState } from 'react';

import { useStore } from 'store/store';
import Button from 'components/Button/Button';
import TextBox from 'components/Forms/TextBox/TextBox';
import ModalScreen from 'components/ModalScreen/ModalScreen';

import 'components/ModalScreen/ModalScreen.scss';

interface SaveProjectProps {
  close: () => void;
  visible: boolean;
}

/**
 * Modal screen to save the current project.
 */
function SaveProject(props: SaveProjectProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const [projectName, setProjectName] = useState('');
  const [state, dispatch] = useStore();

  // Clear name when modal opened so the input is ready for a new project name.
  useEffect(() => {
    if (props.visible) {
      setProjectName('');
      setErrorMessage('');
    }
  }, [props.visible]);

  // User clicked the save button.
  function save() {
    try {
      setErrorMessage('');
      dispatch('ADD_PROJECT', { ...state.currentProject, name: projectName });
      dispatch('SET_PROJECT_NAME', projectName);
      props.close();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  return (
    <ModalScreen
      close={props.close}
      heading="Personalize Your List"
      visible={props.visible}
    >
      <p>
        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
        fugit, sed quia conseq.
      </p>
      <TextBox
        label="Save your project"
        name="Save your project"
        onChange={(val) => {
          setProjectName(val);
        }}
        placeholder="Enter title here"
        value={projectName}
      />
      <div className="error-message">{errorMessage}</div>
      <Button type="basic" wide onClick={save}>
        Save
      </Button>
    </ModalScreen>
  );
}

export default SaveProject;
