import { CalculationResponse } from 'const';
import { initStore } from './store';

/**
 * Creates the store for managing state shared across the app.
 */
const configureResultStore = () => {
  const actions = {
    // Store the fetched calculated results.
    SET_RESULT: (state: any, data: CalculationResponse) => ({
      result: data,
    }),
  };

  initStore(actions, {
    result: {
      competitorAnnualEnergyCost: -1,
      competitorLifetimeCarbonOutput: -1,
      competitorLifetimeCarbonOutputPerBox: -1,
      competitorLifetimeCarbonOutputPerFoot: -1,
      competitorLifetimeCarbonOutputPerLiter: -1,
      competitorLifetimeEnergyCost: -1,
      competitorLifetimeEnergyCostPerBox: -1,
      competitorLifetimeEnergyCostPerFoot: -1,
      competitorLifetimeEnergyCostPerLiter: -1,
      competitorLifetimeEquipmentCost: -1,
      competitorLifetimeEquipmentCostPerBox: -1,
      competitorLifetimeEquipmentCostPerFoot: -1,
      competitorLifetimeEquipmentCostPerLiter: -1,
      competitorLifetimeTotalCost: -1,
      competitorLifetimeTotalCostPerBox: -1,
      competitorLifetimeTotalCostPerFoot: -1,
      competitorLifetimeTotalCostPerLiter: -1,
      competitorTotalFloorSpace: -1,
      competitorTotalFloorSpaceFeet: -1,
      competitorsNeeded: -1,
      freezerAnnualEnergyCost: -1,
      freezerLifetimeCarbonOutput: -1,
      freezerLifetimeCarbonOutputPerBox: -1,
      freezerLifetimeCarbonOutputPerFoot: -1,
      freezerLifetimeCarbonOutputPerLiter: -1,
      freezerLifetimeEnergyCost: -1,
      freezerLifetimeEnergyCostPerBox: -1,
      freezerLifetimeEnergyCostPerFoot: -1,
      freezerLifetimeEnergyCostPerLiter: -1,
      freezerLifetimeEquipmentCost: -1,
      freezerLifetimeEquipmentCostPerBox: -1,
      freezerLifetimeEquipmentCostPerFoot: -1,
      freezerLifetimeEquipmentCostPerLiter: -1,
      freezerLifetimeTotalCost: -1,
      freezerLifetimeTotalCostPerBox: -1,
      freezerLifetimeTotalCostPerFoot: -1,
      freezerLifetimeTotalCostPerLiter: -1,
      freezerTotalFloorSpace: -1,
      freezerTotalFloorSpaceFeet: -1,
      freezersNeeded: -1,
    },
  });
};

export default configureResultStore;
