import React from 'react';
import { useStore } from 'store/store';

import './Loading.scss';

/**
 * Loading screen!
 */
function Loading() {
  const state = useStore()[0];

  return (
    <div className={`loading ${state.loadingScreenActive ? 'is-active' : ''}`}>
      <div className="wrapper">
        <div className="box-wrap">
          <div className="box one"></div>
          <div className="box two"></div>
          <div className="box three"></div>
          <div className="box four"></div>
          <div className="box five"></div>
          <div className="box six"></div>
        </div>
      </div>

      <h4 className="copy">LOADING</h4>
    </div>
  );
}

export default Loading;
