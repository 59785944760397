export interface Project {
  name: string;
  competitor: string;
  competitorCost: string;
  competitors: Competitor[];
  country: string;
  currency: string;
  energyCostCustom: string;
  energyCostOverride: false;
  freezer: string;
  measureUnits: string;
  state: string;
  freezerCountNeeded: string;
  storageRequirements: string;
  storageUnits: string;
  costOverride: string;
}

export interface Competitor {
  name: string;
  quantity: string;
  cost: string;
}

export interface ModelListItem {
  id: number;
  model: string;
}

export interface CalculationResponse {
  competitorAnnualEnergyCost: number;
  competitorLifetimeCarbonOutput: number;
  competitorLifetimeCarbonOutputPerBox: number;
  competitorLifetimeCarbonOutputPerFoot: number;
  competitorLifetimeCarbonOutputPerLiter: number;
  competitorLifetimeEnergyCost: number;
  competitorLifetimeEnergyCostPerBox: number;
  competitorLifetimeEnergyCostPerFoot: number;
  competitorLifetimeEnergyCostPerLiter: number;
  competitorLifetimeEquipmentCost: number;
  competitorLifetimeEquipmentCostPerBox: number;
  competitorLifetimeEquipmentCostPerFoot: number;
  competitorLifetimeEquipmentCostPerLiter: number;
  competitorLifetimeTotalCost: number;
  competitorLifetimeTotalCostPerBox: number;
  competitorLifetimeTotalCostPerFoot: number;
  competitorLifetimeTotalCostPerLiter: number;
  competitorTotalFloorSpace: number;
  competitorTotalFloorSpaceFeet: number;
  competitorsNeeded: number;
  freezerAnnualEnergyCost: number;
  freezerLifetimeCarbonOutput: number;
  freezerLifetimeCarbonOutputPerBox: number;
  freezerLifetimeCarbonOutputPerFoot: number;
  freezerLifetimeCarbonOutputPerLiter: number;
  freezerLifetimeEnergyCost: number;
  freezerLifetimeEnergyCostPerBox: number;
  freezerLifetimeEnergyCostPerFoot: number;
  freezerLifetimeEnergyCostPerLiter: number;
  freezerLifetimeEquipmentCost: number;
  freezerLifetimeEquipmentCostPerBox: number;
  freezerLifetimeEquipmentCostPerFoot: number;
  freezerLifetimeEquipmentCostPerLiter: number;
  freezerLifetimeTotalCost: number;
  freezerLifetimeTotalCostPerBox: number;
  freezerLifetimeTotalCostPerFoot: number;
  freezerLifetimeTotalCostPerLiter: number;
  freezerTotalFloorSpace: number;
  freezerTotalFloorSpaceFeet: number;
  freezersNeeded: number;
}

export const COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Canada',
  'Croatia',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'United States',
];

export const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland & Labrador',
  'Nova Scotia',
  'Northwest Territories',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon Territory',
];
