import React, { useEffect, useState } from 'react';

import { useStore } from 'store/store';
import Button from 'components/Button/Button';
import TextBox from 'components/Forms/TextBox/TextBox';
import ModalScreen from 'components/ModalScreen/ModalScreen';

import 'components/ModalScreen/ModalScreen.scss';

interface RenameProjectProps {
  close: () => void;
  visible: boolean;
}

/**
 * Modal screen to rename the current project.
 */
function RenameProject(props: RenameProjectProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const [projectName, setProjectName] = useState('');
  const [state, dispatch] = useStore();

  useEffect(() => {
    if (props.visible) {
      setProjectName(state.projectRenameInfo.name);
      setErrorMessage('');
    }
  }, [props.visible, state.projectRenameInfo.name]);

  // User clicked the rename button.
  function rename() {
    try {
      setErrorMessage('');
      dispatch('RENAME_PROJECT', projectName);
      props.close();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  return (
    <ModalScreen
      close={props.close}
      heading="Rename Your Saved Project"
      visible={props.visible}
    >
      <p>
        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
        fugit, sed quia conseq.
      </p>
      <TextBox
        label="Change your project"
        name="Change your project"
        onChange={(val) => {
          setProjectName(val);
        }}
        placeholder="Enter title here"
        value={projectName}
      />
      <div className="error-message">{errorMessage}</div>
      <Button type="basic" wide onClick={rename}>
        Rename
      </Button>
    </ModalScreen>
  );
}

export default RenameProject;
