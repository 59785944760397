import React from 'react';
import { useStore } from 'store/store';
import {
  formatCurrency,
  formatCurrencyWrapSymbol,
  formatKilograms,
  formatNumber,
  formatSquareMeters,
} from 'utility';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Button from 'components/Button/Button';
import Footer from 'components/Footer/Footer';
import FreezerGraphic from 'components/FreezerGraphic/FreezerGraphic';
import Panel from 'components/Panel/Panel';
import ResultsSummary from 'components/ResultsSummary/ResultsSummary';
import SavingsPanel from 'components/SavingsPanel/SavingsPanel';
import TabbedPanel from 'features/TabbedPanel/TabbedPanel';
import './MultiResults.scss';

/**
 * Top level Panel that outputs the initial results for the multiple freezer
 * comparison feature.
 */
function MultiResults() {
  const [state, dispatch] = useStore();

  return (
    <Panel id="multi-results">
      <div className="multi-results">
        <Breadcrumbs
          crumbs={[
            {
              onClick: () => {
                dispatch('SET_ACTIVE_PANEL', 'home');
              },
              title: 'Home',
            },
            {
              onClick: () => {
                dispatch('SET_ACTIVE_PANEL', 'multi-comparison');
              },
              title: 'Replace multiple freezers',
            },
            {
              title: 'Compare the Difference',
            },
          ]}
        />

        <FreezerGraphic title="Compare the Difference" />

        <div className="right">
          <TabbedPanel
            tabs={[
              {
                title: 'Energy Savings',
                content: (
                  <SavingsPanel
                    copy="Annual savings available if the Stirling Ultracold SU780XLE freezer is selected."
                    savings={formatCurrencyWrapSymbol(
                      state.result.competitorAnnualEnergyCost -
                        state.result.freezerAnnualEnergyCost,
                      state.currentProject.currency,
                      0
                    )}
                    freezerModel={state.currentProject.freezer}
                    competitorModel="Competitors"
                    data={[
                      {
                        description: 'Number of freezers needed',
                        freezer: formatNumber(state.result.freezersNeeded, 0),
                        competitor: formatNumber(
                          state.result.competitorsNeeded,
                          0
                        ),
                      },
                      {
                        description: 'Energy cost per kWh',
                        freezer: formatCurrency(
                          state.result.energyCost,
                          state.currentProject.currency
                        ),
                        competitor: formatCurrency(
                          state.result.energyCost,
                          state.currentProject.currency
                        ),
                      },
                      {
                        description: 'Lifetime energy cost per L',
                        freezer: formatCurrency(
                          state.result.freezerLifetimeEnergyCostPerLiter,
                          state.currentProject.currency
                        ),
                        competitor: formatCurrency(
                          state.result.competitorLifetimeEnergyCostPerLiter,
                          state.currentProject.currency
                        ),
                      },
                      {
                        description: 'Lifetime energy cost per CuFt',
                        freezer: formatCurrency(
                          state.result.freezerLifetimeEnergyCostPerFoot,
                          state.currentProject.currency
                        ),
                        competitor: formatCurrency(
                          state.result.competitorLifetimeEnergyCostPerFoot,
                          state.currentProject.currency
                        ),
                      },
                      {
                        description: 'Lifetime energy cost per 2" box',
                        freezer: formatCurrency(
                          state.result.freezerLifetimeEnergyCostPerBox,
                          state.currentProject.currency
                        ),
                        competitor: formatCurrency(
                          state.result.competitorLifetimeEnergyCostPerBox,
                          state.currentProject.currency
                        ),
                      },
                    ]}
                  />
                ),
              },
              ...(state.result.competitorLifetimeEquipmentCost
                ? [
                    {
                      title: 'Equipment Costs',
                      content: (
                        <SavingsPanel
                          copy="Difference lifetime equipment cost"
                          savings={formatCurrencyWrapSymbol(
                            state.result.competitorLifetimeEquipmentCost -
                              state.result.freezerLifetimeEquipmentCost,
                            state.currentProject.currency,
                            0
                          )}
                          freezerModel={state.currentProject.freezer}
                          competitorModel="Competitors"
                          data={[
                            {
                              description: 'Number of freezers needed',
                              freezer: formatNumber(
                                state.result.freezersNeeded,
                                0
                              ),
                              competitor: formatNumber(
                                state.result.competitorsNeeded,
                                0
                              ),
                            },
                            {
                              description: 'Lifetime equip. cost per L',
                              freezer: formatCurrency(
                                state.result
                                  .freezerLifetimeEquipmentCostPerLiter,
                                state.currentProject.currency
                              ),
                              competitor: formatCurrency(
                                state.result
                                  .competitorLifetimeEquipmentCostPerLiter,
                                state.currentProject.currency
                              ),
                            },
                            {
                              description: 'Lifetime equip. cost per CuFt',
                              freezer: formatCurrency(
                                state.result
                                  .freezerLifetimeEquipmentCostPerFoot,
                                state.currentProject.currency
                              ),
                              competitor: formatCurrency(
                                state.result
                                  .competitorLifetimeEquipmentCostPerFoot,
                                state.currentProject.currency
                              ),
                            },
                            {
                              description: 'Lifetime equip. cost per 2" box',
                              freezer: formatCurrency(
                                state.result.freezerLifetimeEquipmentCostPerBox,
                                state.currentProject.currency
                              ),
                              competitor: formatCurrency(
                                state.result
                                  .competitorLifetimeEquipmentCostPerBox,
                                state.currentProject.currency
                              ),
                            },
                          ]}
                        />
                      ),
                    },
                  ]
                : []),
              {
                title: 'Carbon Reduction',
                content: (
                  <SavingsPanel
                    copy="For improved ESG ratings and less impact on climate change."
                    savings={formatKilograms(
                      state.result.competitorLifetimeCarbonOutput -
                        state.result.freezerLifetimeCarbonOutput,
                      state.currentProject.measureUnits
                    )}
                    freezerModel={state.currentProject.freezer}
                    competitorModel="Competitors"
                    data={[
                      {
                        description: 'Number of freezers needed',
                        freezer: formatNumber(state.result.freezersNeeded, 0),
                        competitor: formatNumber(
                          state.result.competitorsNeeded,
                          0
                        ),
                      },
                      {
                        description: 'Carbon output per kWh',
                        freezer: state.result.carbonCost,
                        competitor: state.result.carbonCost,
                      },
                      {
                        description: 'Lifetime carbon output per L',
                        freezer: `${formatKilograms(
                          state.result.freezerLifetimeCarbonOutputPerLiter,
                          state.currentProject.measureUnits
                        )}`,
                        competitor: `${formatKilograms(
                          state.result.competitorLifetimeCarbonOutputPerLiter,
                          state.currentProject.measureUnits
                        )}`,
                      },
                      {
                        description: 'Lifetime carbon output per CuFt',
                        freezer: `${formatKilograms(
                          state.result.freezerLifetimeCarbonOutputPerFoot,
                          state.currentProject.measureUnits
                        )}`,
                        competitor: `${formatKilograms(
                          state.result.competitorLifetimeCarbonOutputPerFoot,
                          state.currentProject.measureUnits
                        )}`,
                      },
                      {
                        description: 'Lifetime carbon output per 2" box',
                        freezer: `${formatKilograms(
                          state.result.freezerLifetimeCarbonOutputPerBox,
                          state.currentProject.measureUnits
                        )}`,
                        competitor: `${formatKilograms(
                          state.result.competitorLifetimeCarbonOutputPerBox,
                          state.currentProject.measureUnits
                        )}`,
                      },
                    ]}
                  />
                ),
              },
              {
                title: 'Space Savings',
                content: (
                  <SavingsPanel
                    copy="Difference floor space"
                    savings={formatSquareMeters(
                      state.result.competitorTotalFloorSpace -
                        state.result.freezerTotalFloorSpace,
                      state.currentProject.measureUnits
                    )}
                    freezerModel={state.currentProject.freezer}
                    competitorModel="Competitors"
                    data={[
                      {
                        description: 'Number of freezers needed',
                        freezer: formatNumber(state.result.freezersNeeded, 0),
                        competitor: formatNumber(
                          state.result.competitorsNeeded,
                          0
                        ),
                      },
                      {
                        description: 'Total floor space required (m2)',
                        freezer: formatNumber(
                          state.result.freezerTotalFloorSpace
                        ),
                        competitor: formatNumber(
                          state.result.competitorTotalFloorSpace
                        ),
                        floorSpaceComparison: true,
                        floorSpaceCopy:
                          'Lorem ipsum dolor set amet lorem ipsum dolor set consectutor magni dolores eos qui ratione voluptate set amet.',
                        floorSpaceUnits: 'm&sup2;',
                      },
                      {
                        description: 'Total floor space required (SqFt)',
                        freezer: formatNumber(
                          state.result.freezerTotalFloorSpaceFeet
                        ),
                        competitor: formatNumber(
                          state.result.competitorTotalFloorSpaceFeet
                        ),
                        floorSpaceComparison: true,
                        floorSpaceCopy:
                          'Lorem ipsum dolor set amet lorem ipsum dolor set consectutor magni dolores eos qui ratione voluptate set amet.',
                        floorSpaceUnits: 'SqFt',
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
          <ResultsSummary
            multi={true}
            copy="will be saved over a 12-year lifetime. Please note, this 12-year lifetime is not promised with many competitive units, but these calculations are conservative. "
            savings={formatCurrencyWrapSymbol(
              state.result.competitorLifetimeTotalCost -
                state.result.freezerLifetimeTotalCost,
              state.currentProject.currency,
              0
            )}
            ctas={[
              <Button
                type="basic"
                onClick={() => {
                  dispatch('SET_ACTIVE_PANEL', 'total-calculation');
                }}
              >
                View total Savings data
              </Button>,
              <Button
                type="border"
                onClick={() => {
                  console.log('click');
                }}
              >
                Download Savings data
              </Button>,
            ]}
          />
        </div>

        <Footer />
      </div>
    </Panel>
  );
}

export default MultiResults;
