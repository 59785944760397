import React, { useEffect, useState } from 'react';
import { COUNTRIES, STATES, ModelListItem, Competitor } from 'const';
import { useStore } from 'store/store';
import { Post } from 'api';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Button from 'components/Button/Button';
import ComparisonForm from 'components/ComparisonForm/ComparisonForm';
import Footer from 'components/Footer/Footer';
import Footnote from 'components/Footnote/Footnote';
import FreezerGraphic from 'components/FreezerGraphic/FreezerGraphic';
import OnOff from 'components/Forms/OnOff/OnOff';
import Panel from 'components/Panel/Panel';
import Plus from 'icons/Plus';
import Radio from 'components/Forms/Radio/Radio';
import SelectBox from 'components/Forms/SelectBox/SelectBox';
import TextBox from 'components/Forms/TextBox/TextBox';
import XMarkIcon from 'icons/XMarkIcon';
import './MultiComparison.scss';

/**
 * Full panel for the multiple freezer comparison form.
 */
function MultiComparison() {
  const [state, dispatch] = useStore();
  const [errorMessage, setErrorMessage] = useState('');

  // Set the calculation type here for better breadcrumbs later.
  useEffect(() => {
    if (state.activePanel === 'multi-comparison') {
      dispatch('SET_CALCULATION_TYPE', 'multiple');
      setErrorMessage('');
    }
  }, [state.activePanel, dispatch]);

  /**
   * Validate form fields for completion and formatting.
   */
  function handleFormValidation() {
    let message = '';

    if (state.currentProject.freezer === '') {
      message = 'Select a Stirling ULT freezer.';
    } else if (state.currentProject.country === '') {
      message = 'Select a country.';
    } else if (
      state.currentProject.country === 'United States' &&
      state.currentProject.state === ''
    ) {
      message = 'Select a State.';
    } else if (state.currentProject.currency === '') {
      message = 'Select a currency.';
    } else if (
      state.currentProject.energyCostOverride === true &&
      state.currentProject.energyCostCustom === ''
    ) {
      message = 'Enter the energy cost.';
    } else if (
      state.currentProject.energyCostOverride === true &&
      isNaN(state.currentProject.energyCostCustom)
    ) {
      message = 'Energy cost must be a number.';
    } else {
      // Check competitors.
      for (let i = 0; i < state.currentProject.competitors.length; i++) {
        if (
          state.currentProject.competitors[i]['name'] === '' ||
          state.currentProject.competitors[i]['quantity'] === ''
        ) {
          message = 'Select a model and quantity for each ULT to replace.';
        } else if (isNaN(state.currentProject.competitors[i]['quantity'])) {
          message = 'The quantity of ULTs to replace must be a number.';
        } else if (
          state.currentProject.competitors[i]['cost'] !== '' &&
          isNaN(state.currentProject.competitors[i]['cost'])
        ) {
          message = 'Competitor price must be a number.';
        }
      }
    }

    if (message === '') {
      handleCalculation();
    } else {
      setErrorMessage(message);
    }
  }

  /**
   * Posts the form and handles the results, then taking the user to the multiple results page.
   */
  function handleCalculation() {
    dispatch('ACTIVATE_LOADING_SCREEN');

    const competitors = state.currentProject.competitors.map(
      (competitor: Competitor) => {
        return {
          id: state.competitorModels.find(
            (comp: ModelListItem) => comp.model === competitor.name
          ).id,
          number: competitor.quantity,
          cost: competitor.cost ? competitor.cost : null,
        };
      }
    );

    Post('calculate/multiple', {
      freezer_id: state.freezerModels.find(
        (freezer: ModelListItem) =>
          freezer.model === state.currentProject.freezer
      ).id,
      energy_cost: state.currentProject.energyCostOverride
        ? state.currentProject.energyCostCustom
        : null,
      currency: state.currentProject.currency.toLowerCase(),
      country: state.currentProject.country,
      state: state.currentProject.state ? state.currentProject.state : null,
      competitors: competitors,
    }).then((response) => {
      dispatch('SET_RESULT', response.data);
      dispatch('SET_ACTIVE_PANEL', 'multi-results');
      dispatch('DEACTIVATE_LOADING_SCREEN');
    });
  }

  /**
   * Add new competitor to current project with default values.
   */
  function handleAddCompetitor() {
    dispatch('UPDATE_PROJECT_COMPETITOR_ADD', {
      name: '',
      quantity: '',
      cost: '',
    });
  }

  /**
   * Delete a competitor ID from the list.
   */
  function handleDeleteCompetitor(id: number) {
    dispatch('UPDATE_PROJECT_COMPETITOR_DELETE', id);
  }

  /**
   * User changed a field value for a competitor.
   * @param id: row id, used to track all fields for this row.
   * @param field: name of the field. Either 'name' or 'id'.
   * @param value: the value of the field.
   */
  function handleCompetitorValue(id: number, field: string, value: string) {
    dispatch('UPDATE_PROJECT_COMPETITOR_VALUE', {
      id: id,
      field: field,
      value: value,
    });
  }

  /**
   * User changed value of country select box.
   */
  function handleCountryChange(val: string) {
    // Show the state select if United States is selected.
    if (state.currentProject.country !== 'United States') {
      dispatch('UPDATE_PROJECT_VALUE', { field: 'state', value: '' });
    }

    dispatch('UPDATE_PROJECT_VALUE', { field: 'country', value: val });
  }

  /**
   * User changed value of currency.
   */
  function handleCurrencyChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'currency', value: val });
  }

  /**
   * User changed value of the custom energy cost text box.
   */
  function handleEnergyCostCustom(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'energyCostCustom', value: val });
  }

  /**
   * Enable/disable the energy cost override text box.
   */
  function handleEnergyCostOverride(val: boolean) {
    dispatch('UPDATE_PROJECT_VALUE', {
      field: 'energyCostOverride',
      value: val,
    });
  }

  /**
   * User changed value of the freezer select box.
   */
  function handleFreezerChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'freezer', value: val });
  }

  /**
   * User changed value of the measuerd units.
   */
  function handleMedsureUnitsChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'measureUnits', value: val });
  }

  /**
   * User changed value of the state select box.
   */
  function handleStateChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'state', value: val });
  }

  return (
    <Panel id="multi-comparison">
      <div className="multi-comparison">
        <Breadcrumbs
          crumbs={[
            {
              onClick: () => {
                dispatch('SET_ACTIVE_PANEL', 'home');
              },
              title: 'Home',
            },
            {
              title: 'Replace multiple freezers',
            },
          ]}
        />
        <FreezerGraphic
          title="Personalize Your Savings"
          copy="Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est."
          background={true}
        />
        <div className="right">
          <Button
            type="small"
            onClick={() => {
              dispatch('TOGGLE_SAVE_MODAL');
            }}
          >
            Save Project
            <Plus iconTitle="save-project-plus" />
          </Button>
          <ComparisonForm>
            <div className="section">
              <SelectBox
                label="Stirling ULT"
                name="singleFreezer"
                placeholder="Select model ..."
                options={state.freezerModels.map(
                  (freezer: ModelListItem) => freezer.model
                )}
                onChange={handleFreezerChange}
                value={state.currentProject.freezer}
              />
            </div>
            <div className="section">
              <div className="typo-form-header reduce-margin">
                ULTs you want to replace
              </div>
              {state.currentProject.competitors.map(
                (
                  item: { name: string; quantity: string; cost: string },
                  index: number
                ) => (
                  <div key={index} className="left-dominant">
                    <SelectBox
                      name={`multiCompetitor-${index}`}
                      placeholder="Select model ..."
                      options={state.competitorModels.map(
                        (competitor: ModelListItem) => competitor.model
                      )}
                      onChange={(val) => {
                        handleCompetitorValue(index, 'name', val);
                      }}
                      value={item.name}
                    />
                    <TextBox
                      name={`multiCompetitorNum-${index}`}
                      placeholder="0"
                      maxLength={2}
                      onChange={(val) => {
                        handleCompetitorValue(index, 'quantity', val);
                      }}
                      value={item.quantity}
                    />
                    <TextBox
                      name={`multiCompetitorNum-${index}`}
                      placeholder="Enter price (optional)"
                      onChange={(val) => {
                        handleCompetitorValue(index, 'cost', val);
                      }}
                      value={item.cost}
                    />

                    {state.currentProject.competitors.length > 1 ? (
                      <button
                        className="delete-competitor"
                        onClick={() => {
                          handleDeleteCompetitor(index);
                        }}
                      >
                        <XMarkIcon iconTitle="delete-competitor-icon" />
                      </button>
                    ) : null}
                  </div>
                )
              )}

              <Footnote note="Freezer purchase price must be entered to compare equipment costs and calculate ROI." />

              <button className="add-competitor" onClick={handleAddCompetitor}>
                <Plus iconTitle="add-competitor-plus" />
                Add another model
              </button>
            </div>
            <div className="section">
              <div className="fifty-fifty">
                <SelectBox
                  label="Determine your energy costs by location"
                  name="multiCountry"
                  placeholder="Country"
                  options={COUNTRIES}
                  onChange={handleCountryChange}
                  value={state.currentProject.country}
                />
                <SelectBox
                  className={`animate-visibility ${
                    state.currentProject.country === 'United States'
                      ? 'is-visible'
                      : ''
                  }`}
                  name="multiState"
                  placeholder="State"
                  options={STATES}
                  onChange={handleStateChange}
                  value={state.currentProject.state}
                />
              </div>
              <div className="full">
                <SelectBox
                  name="multiCurrency"
                  placeholder="Currency"
                  options={['USD', 'EUR']}
                  onChange={handleCurrencyChange}
                  value={state.currentProject.currency}
                />
              </div>
            </div>
            <div className="section">
              <div className="fifty-fifty">
                <Radio
                  label="Select your unit of measure"
                  name="multiMeasureUnits"
                  options={['Metric', 'Imperial']}
                  onChange={handleMedsureUnitsChange}
                  value={state.currentProject.measureUnits}
                />
                <OnOff
                  label="Energy costs"
                  description="Override cost per kWh"
                  name="multiEnergyCostOverride"
                  onChange={handleEnergyCostOverride}
                  value={state.currentProject.energyCostOverride}
                />
              </div>
              <div
                className={`fifty-fifty animate-height ${
                  state.currentProject.energyCostOverride ? 'is-visible' : ''
                }`}
              >
                <div></div>
                <TextBox
                  name="multiCostOverride"
                  placeholder="Enter your cost per kWh"
                  onChange={handleEnergyCostCustom}
                  value={state.currentProject.energyCostCustom}
                />
              </div>
            </div>
            <div className={`error-message ${errorMessage ? 'is-active' : ''}`}>
              {errorMessage}
            </div>
            <Button type="basic" onClick={handleFormValidation}>
              Calculate your Savings
            </Button>
          </ComparisonForm>
        </div>

        <Footer />
      </div>
    </Panel>
  );
}

export default MultiComparison;
