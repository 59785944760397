import { useEffect, useState } from 'react';

type Action = {
  [key: string]: (state: any, payload?: any) => any;
};

// Initialize these outside of the scope of the hook so we create a "global" store.
let actions: Action = {};
let globalState = {};
let listeners: any[] = [];

/**
 * Dispatches the given action, updating the state in some way.
 */
const dispatch: any = (actionIdentifier: string, withPayload?: any) => {
  const newState = actions[actionIdentifier](globalState, withPayload);
  globalState = { ...globalState, ...newState };

  for (const listener of listeners) {
    listener(globalState);
  }
};

/**
 * Custom hook for reading and writing to the global state store.
 */
export const useStore = () => {
  const setState = useState(globalState)[1];

  useEffect(() => {
    listeners.push(setState);

    return () => {
      listeners = listeners.filter((keepIf) => keepIf !== setState);
    };
  }, [setState]);

  return [globalState, dispatch];
};

/**
 * Initialize a new store with the given actions and initial state.
 */
export const initStore = (userActions: {}, initialState: {}) => {
  if (initialState) globalState = { ...globalState, ...initialState };
  actions = { ...actions, ...userActions };
};
