import React from 'react';

import './FreezerGraphic.scss';

import atomGraphic from 'images/atom-graphic.png';
import freezerImage from 'images/freezer.png';

interface FreezerGraphicProps {
  background?: boolean;
  copy?: string;
  title?: string;
}

/**
 * Outputs the freezer graphic and green circle with text inside.
 * Optionally pass in additional sub copy for below the graphic.
 * TODO: Support optionally enabling the atom background.
 */
function FreezerGraphic(props: FreezerGraphicProps) {
  return (
    <div className="freezer-graphic">
      {props.background ? (
        <img className="background" src={atomGraphic} alt="Atomic Graphic" />
      ) : (
        ''
      )}

      <div className="elements">
        <img className="freezer" src={freezerImage} alt="Stand Up Freezer" />
        <div className="green-circle" />
        <div className="title">{props.title}</div>
      </div>
      {props.copy && <p>{props.copy}</p>}
    </div>
  );
}

export default FreezerGraphic;
