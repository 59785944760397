import React from 'react';

import './Radio.scss';

interface RadioProps {
  label?: string;
  name: string;
  onChange: (value: string) => void;
  options: string[];
  value: string;
}

/**
 * Radio buttons with any number of allowed options. Passes back the newly selected
 * option when the value changes.
 */
function Radio(props: RadioProps) {
  // Default if the state value is empty.
  if (props.value === '') {
    props.onChange(props.options[0]);
  }

  /**
   * Update the radio's value.
   */
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(e.target.value);
  }

  return (
    <div className="radio-wrap">
      {props.label ? (
        <div className="heading typo-form-header">{props.label}</div>
      ) : (
        ''
      )}
      <div className="items">
        {props.options.map((item: string, index: number) => (
          <label key={index}>
            <input
              type="radio"
              name={props.name}
              value={item}
              checked={props.value === item}
              onChange={handleChange}
            />
            {item}
          </label>
        ))}
      </div>
    </div>
  );
}

export default Radio;
