import React, { PropsWithChildren } from 'react';

import './MenuButton.scss';

interface MenuButtonProps {
  onClick: () => void;
  menu?: boolean;
}

/**
 * Button styled for the project links in the header
 */
function MenuButton(props: PropsWithChildren<MenuButtonProps>) {
  return (
    <button
      className={`menu-button ${props.menu ? 'projects-menu' : ''}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export default MenuButton;
