import React from 'react';

import './OrLine.scss';

/**
 * Styled separator line with an "or" in the middle.
 */
function OrLine() {
  return (
    <div className="or-line">
      <span></span>
      <div>or</div>
      <span></span>
    </div>
  );
}

export default OrLine;
