import React from 'react';

import './Breadcrumbs.scss';

interface BreadcrumbsProps {
  crumbs: {
    onClick?: () => void;
    title: string;
  }[];
}

/**
 * Outputs the chain of given breadcrumbs, optionally adding click functionality
 * to each crumb.
 */
function Breadcrumbs(props: BreadcrumbsProps) {
  return (
    <div className="breadcrumbs">
      {props.crumbs.map((crumb, index) =>
        crumb.onClick ? (
          <div key={index}>
            <button onClick={crumb.onClick}>{crumb.title}</button>
          </div>
        ) : (
          <div key={index}>{crumb.title}</div>
        )
      )}
    </div>
  );
}

export default Breadcrumbs;
