import React, { PropsWithChildren } from 'react';

import './Button.scss';

interface ButtonProps {
  onClick: () => void;
  type: 'basic' | 'border' | 'small';
  wide?: boolean;
  icon?: boolean;
}

/**
 * Button component that covers a variety of styles, executing the passed in
 * function when the button is clicked.
 */
function Button(props: PropsWithChildren<ButtonProps>) {
  return (
    <button
      className={`button ${props.type} ${props.wide ? 'wide' : ''} ${
        props.icon ? 'icon' : ''
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export default Button;
