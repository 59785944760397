import { Project } from 'const';
import { initStore } from './store';

/**
 * Creates the store for managing saved projects.
 */
const configureProjectStore = () => {
  const actions = {
    /**
     * Save the current working project.
     */
    ADD_PROJECT: (state: any, project: Project) => {
      if (project.name.length === 0) {
        throw new Error('Please enter a project name.');
      }

      for (const item of state.projects) {
        if (item.name === project.name) {
          throw new Error('This project name is in use, choose another.');
        }
      }

      // Deep copy the project to get nested objects.
      const deepCopy = JSON.parse(JSON.stringify(project));
      const newProjects = state.projects.concat(deepCopy);

      newProjects.sort((a: Project, b: Project) =>
        a.name.localeCompare(b.name)
      );

      localStorage.setItem('projects', JSON.stringify(newProjects));

      return {
        ...state,
        projects: newProjects,
      };
    },

    /**
     * Load a selected project and set it as the current project.
     */
    LOAD_PROJECT: (state: any, name: string) => {
      if (state.projectName === name) {
        return {
          activePanel: 'home',
          projectPanelVisible: !state.projectPanelVisible,
        };
      }

      const p = JSON.parse(localStorage.getItem('projects') || '[]');
      let newCurrentProject = [];

      for (const item of p) {
        if (item.name === name) {
          newCurrentProject = item;
          break;
        }
      }

      return {
        ...state,
        activePanel: 'home',
        currentProject: newCurrentProject,
        projectName: name,
        projectPanelVisible: !state.projectPanelVisible,
      };
    },

    /**
     * Load the existing projects from storage.
     */
    LOAD_PROJECTS: (state: any) => {
      const p = JSON.parse(localStorage.getItem('projects') || '[]');

      return {
        ...state,
        projects: p,
      };
    },

    /**
     * Rename a saved project.
     */
    RENAME_PROJECT: (state: any, newName: string) => {
      if (newName.length === 0) {
        throw new Error('Please enter a project name.');
      }

      for (let i = 0; i < state.projects.length; i++) {
        const item = state.projects[i];

        if (item.name === newName) {
          if (i === state.projectRenameInfo.index) {
            return;
          } else {
            throw new Error('This project name is in use, choose another.');
          }
        }
      }

      const newProjects = state.projects;
      newProjects[state.projectRenameInfo.index].name = newName;

      newProjects.sort((a: Project, b: Project) =>
        a.name.localeCompare(b.name)
      );

      localStorage.setItem('projects', JSON.stringify(newProjects));

      // If this is the active project, update that too.
      let newProjectName = state.projectName;
      if (state.projectName === state.projectRenameInfo.name) {
        newProjectName = newName;
      }

      return {
        projects: newProjects,
        projectName: newProjectName,
      };
    },

    /**
     * Store the current projet name, used for the Project Panel menu.
     */
    SET_PROJECT_NAME: (state: any, name: string) => ({
      projectName: name,
    }),

    /**
     * Store the index and name of the project being renamed.
     */
    SET_PROJECT_RENAME_INFO: (state: any, projectName: string) => {
      const projectIndex = state.projects.findIndex(
        (p: Project) => p.name === projectName
      );

      return {
        projectRenameInfo: {
          index: projectIndex,
          name: projectName,
        },
      };
    },

    /**
     * Remove a saved project.
     */
    REMOVE_PROJECT: (state: any, name: string) => {
      let filteredProjects = state.projects.filter(
        (item: any) => item.name !== name
      );

      localStorage.setItem('projects', JSON.stringify(filteredProjects));

      // Check if this is the current project
      let projectName = name === state.projectName ? '' : state.projectName;

      // Close project panel if there are no more saved projects.
      let projectPanelVisibility = filteredProjects.length !== 0;

      return {
        ...state,
        projects: filteredProjects,
        projectName: projectName,
        projectPanelVisible: projectPanelVisibility,
      };
    },

    /**
     * Reset the current project state.
     */
    START_A_NEW_PROJECT: (state: any) => {
      const resetProject: Project = {
        name: '',
        competitor: '',
        competitorCost: '',
        competitors: [
          {
            name: '',
            quantity: '',
            cost: '',
          },
        ],
        country: '',
        currency: '',
        energyCostCustom: '',
        energyCostOverride: false,
        freezer: '',
        measureUnits: 'Metric',
        state: '',
        freezerCountNeeded: '',
        storageRequirements: '',
        storageUnits: 'Liters',
        costOverride: '',
      };

      return {
        currentProject: resetProject,
        projectName: '',
      };
    },

    /**
     * Show or hide the project panel.
     */
    TOGGLE_PROJECT_PANEL: (state: any) => ({
      projectPanelVisible: !state.projectPanelVisible,
    }),

    /**
     * Update a single value in the current project object.
     * @param data.field: name of field being updated.
     * @param data.value: value of field being updated.
     */
    UPDATE_PROJECT_VALUE(state: any, data: { field: string; value: string }) {
      const updatedProject = { ...state.currentProject };
      updatedProject[data.field] = data.value;

      return {
        ...state,
        currentProject: updatedProject,
      };
    },

    /**
     * Add a competitor to the current project.
     * @param data.name: selected name for this competitor.
     * @param data.quantity: selected number of freezers of this type.
     */
    UPDATE_PROJECT_COMPETITOR_ADD(
      state: any,
      data: { name: string; quantity: string; cost: string }
    ) {
      const newItem = {
        name: data.name,
        quantity: data.quantity,
        cost: data.cost,
      };
      const newCompetitors = state.currentProject.competitors.concat(newItem);

      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          competitors: newCompetitors,
        },
      };
    },

    /**
     * Delete a competitor from the current project based.
     * @param id: the row index to delete.
     */
    UPDATE_PROJECT_COMPETITOR_DELETE(state: any, id: number) {
      const updatedCompetitors = state.currentProject.competitors;

      if (updatedCompetitors.length === 1) {
        // We need to leave one competitor selection, so just clear its fields.
        updatedCompetitors[0].name = '';
        updatedCompetitors[0].quantity = '';
        updatedCompetitors[0].cost = '';
      } else {
        updatedCompetitors.splice(id, 1);
      }

      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          competitors: updatedCompetitors,
        },
      };
    },

    /**
     * Update a single value for a competitor in the current project.
     * @param data.id: row id, used to track all fields for this row.
     * @param data.field: name of the field. Either 'name' or 'id'.
     * @param data.value: the value of the field.
     */
    UPDATE_PROJECT_COMPETITOR_VALUE(
      state: any,
      data: { id: number; field: string; value: string }
    ) {
      const updatedCompetitors = state.currentProject.competitors;
      updatedCompetitors[data.id][data.field] = data.value;

      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          competitors: updatedCompetitors,
        },
      };
    },
  };

  initStore(actions, {
    currentProject: {
      name: '',
      competitor: '',
      competitorCost: '',
      competitors: [
        {
          name: '',
          quantity: '',
          cost: '',
        },
      ],
      country: '',
      currency: '',
      energyCostCustom: '',
      energyCostOverride: false,
      freezer: '',
      measureUnits: 'Metric',
      state: '',
      freezerCountNeeded: '',
      storageRequirements: '',
      storageUnits: 'Liters',
      costOverride: '',
    },
    projects: [],
    projectName: '',
    projectPanelVisible: false,
    projectRenameInfo: {
      index: -1,
      name: '',
    },
  });
};

export default configureProjectStore;
