import React from 'react';
import { useStore } from 'store/store';
import {
  formatCurrency,
  formatKilograms,
  formatNumber,
  formatSquareMeters,
} from 'utility';

import ArrowDownToLine from 'icons/ArrowDownToLine';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Button from 'components/Button/Button';
import Panel from 'components/Panel/Panel';
import TotalCalculationDetails from 'components/TotalCalculationDetails/TotalCalculationDetails';
import TotalCalculationRow from 'components/TotalCalculationRow/TotalCalculationRow';
import './TotalCalculation.scss';

/**
 * Single column panel for the Total ROI Calculation screen.
 */
function TotalCalculation() {
  const [state, dispatch] = useStore();

  return (
    <Panel id="total-calculation">
      <div className="total-calculation">
        <Breadcrumbs
          crumbs={[
            {
              onClick: () => {
                dispatch('SET_ACTIVE_PANEL', 'home');
              },
              title: 'Home',
            },
            {
              onClick: () => {
                dispatch(
                  'SET_ACTIVE_PANEL',
                  state.calculationType === 'single'
                    ? 'single-comparison'
                    : 'multi-comparison'
                );
              },
              title: `${
                state.calculationType === 'single'
                  ? '1:1 Comparison Input'
                  : 'Replace Multiple Freezers'
              }`,
            },
            {
              onClick: () => {
                dispatch('SET_ACTIVE_PANEL', 'single-results');
              },
              title: 'Compare the Difference',
            },
            {
              title: 'Total Savings Calculation',
            },
          ]}
        />

        <div className="full-width">
          <h2>Total Savings Calculation</h2>

          <div className="table">
            <div className="heading">
              <div></div>
              <div>
                <h3>{state.currentProject.freezer}</h3>
              </div>
              <div>
                <h3>
                  {state.calculationType === 'single'
                    ? state.currentProject.competitor
                    : 'Competitors'}
                </h3>
              </div>
            </div>

            <TotalCalculationRow
              description="Number of freezers needed"
              freezer={formatNumber(state.result.freezersNeeded, 0)}
              competitor={formatNumber(state.result.competitorsNeeded, 0)}
              title
            />

            <TotalCalculationDetails
              description="Lifetime Energy Savings"
              freezer={formatCurrency(
                state.result.competitorLifetimeEnergyCost -
                  state.result.freezerLifetimeEnergyCost,
                state.currentProject.currency,
                0
              )}
              rows={[
                {
                  content: (
                    <TotalCalculationRow
                      description="Energy cost per kWh"
                      freezer={formatCurrency(
                        state.result.energyCost,
                        state.currentProject.currency
                      )}
                      competitor={formatCurrency(
                        state.result.energyCost,
                        state.currentProject.currency
                      )}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description="Lifetime energy cost per L"
                      freezer={formatCurrency(
                        state.result.freezerLifetimeEnergyCostPerLiter,
                        state.currentProject.currency
                      )}
                      competitor={formatCurrency(
                        state.result.competitorLifetimeEnergyCostPerLiter,
                        state.currentProject.currency
                      )}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description="Lifetime energy cost per CuFt"
                      freezer={formatCurrency(
                        state.result.freezerLifetimeEnergyCostPerFoot,
                        state.currentProject.currency
                      )}
                      competitor={formatCurrency(
                        state.result.competitorLifetimeEnergyCostPerFoot,
                        state.currentProject.currency
                      )}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description='Lifetime energy cost per 2" box'
                      freezer={formatCurrency(
                        state.result.freezerLifetimeEnergyCostPerBox,
                        state.currentProject.currency
                      )}
                      competitor={formatCurrency(
                        state.result.competitorLifetimeEnergyCostPerBox,
                        state.currentProject.currency
                      )}
                    />
                  ),
                },
              ]}
            />

            {state.result.competitorLifetimeEquipmentCost ? (
              <TotalCalculationDetails
                description="Lifetime Equipment Costs"
                freezer={formatCurrency(
                  state.result.competitorLifetimeEquipmentCost -
                    state.result.freezerLifetimeEquipmentCost,
                  state.currentProject.currency,
                  0
                )}
                rows={[
                  {
                    content: (
                      <TotalCalculationRow
                        description="Lifetime equip. cost per L"
                        freezer={formatCurrency(
                          state.result.freezerLifetimeEquipmentCostPerLiter,
                          state.currentProject.currency
                        )}
                        competitor={formatCurrency(
                          state.result.competitorLifetimeEquipmentCostPerLiter,
                          state.currentProject.currency
                        )}
                      />
                    ),
                  },
                  {
                    content: (
                      <TotalCalculationRow
                        description="Lifetime equip. cost per CuFt"
                        freezer={formatCurrency(
                          state.result.freezerLifetimeEquipmentCostPerFoot,
                          state.currentProject.currency
                        )}
                        competitor={formatCurrency(
                          state.result.competitorLifetimeEquipmentCostPerFoot,
                          state.currentProject.currency
                        )}
                      />
                    ),
                  },
                  {
                    content: (
                      <TotalCalculationRow
                        description='Lifetime equip. cost per 2" Box'
                        freezer={formatCurrency(
                          state.result.freezerLifetimeEquipmentCostPerBox,
                          state.currentProject.currency
                        )}
                        competitor={formatCurrency(
                          state.result.competitorLifetimeEquipmentCostPerBox,
                          state.currentProject.currency
                        )}
                      />
                    ),
                  },
                ]}
              />
            ) : (
              ''
            )}

            <TotalCalculationDetails
              description="Lifetime Carbon Reduction"
              freezer={formatKilograms(
                state.result.competitorLifetimeCarbonOutput -
                  state.result.freezerLifetimeCarbonOutput,
                state.currentProject.measureUnits
              )}
              rows={[
                {
                  content: (
                    <TotalCalculationRow
                      description="Carbon output per kWh"
                      freezer={state.result.carbonCost}
                      competitor={state.result.carbonCost}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description="Lifetime carbon cost per L"
                      freezer={formatKilograms(
                        state.result.freezerLifetimeCarbonOutputPerLiter,
                        state.currentProject.measureUnits
                      )}
                      competitor={formatKilograms(
                        state.result.competitorLifetimeCarbonOutputPerLiter,
                        state.currentProject.measureUnits
                      )}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description="Lifetime carbon cost per CuFt"
                      freezer={formatKilograms(
                        state.result.freezerLifetimeCarbonOutputPerFoot,
                        state.currentProject.measureUnits
                      )}
                      competitor={formatKilograms(
                        state.result.competitorLifetimeCarbonOutputPerFoot,
                        state.currentProject.measureUnits
                      )}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description='Lifetime carbon cost per 2" box'
                      freezer={formatKilograms(
                        state.result.freezerLifetimeCarbonOutputPerBox,
                        state.currentProject.measureUnits
                      )}
                      competitor={formatKilograms(
                        state.result.competitorLifetimeCarbonOutputPerBox,
                        state.currentProject.measureUnits
                      )}
                    />
                  ),
                },
              ]}
            />

            <TotalCalculationDetails
              description="Total Space Savings"
              freezer={formatSquareMeters(
                state.result.competitorTotalFloorSpace -
                  state.result.freezerTotalFloorSpace,
                state.currentProject.measureUnits
              )}
              rows={[
                {
                  content: (
                    <TotalCalculationRow
                      description="Total floor space required (m²)"
                      freezer={formatNumber(
                        state.result.freezerTotalFloorSpace
                      )}
                      competitor={formatNumber(
                        state.result.competitorTotalFloorSpace
                      )}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description="Total floor space required (SqFt)"
                      freezer={formatNumber(
                        state.result.freezerTotalFloorSpaceFeet
                      )}
                      competitor={formatNumber(
                        state.result.competitorTotalFloorSpaceFeet
                      )}
                    />
                  ),
                },
              ]}
            />

            <TotalCalculationDetails
              description="Total Cost Savings*"
              freezer={formatCurrency(
                state.result.competitorLifetimeTotalCost -
                  state.result.freezerLifetimeTotalCost,
                state.currentProject.currency,
                0
              )}
              rows={[
                {
                  content: (
                    <TotalCalculationRow
                      description="Lifetime total cost per L"
                      freezer={formatCurrency(
                        state.result.freezerLifetimeTotalCostPerLiter,
                        state.currentProject.currency
                      )}
                      competitor={formatCurrency(
                        state.result.competitorLifetimeTotalCostPerLiter,
                        state.currentProject.currency
                      )}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description="Lifetime total cost per CuFt"
                      freezer={formatCurrency(
                        state.result.freezerLifetimeTotalCostPerFoot,
                        state.currentProject.currency
                      )}
                      competitor={formatCurrency(
                        state.result.competitorLifetimeTotalCostPerFoot,
                        state.currentProject.currency
                      )}
                    />
                  ),
                },
                {
                  content: (
                    <TotalCalculationRow
                      description='Lifetime total cost per 2" box'
                      freezer={formatCurrency(
                        state.result.freezerLifetimeTotalCostPerBox,
                        state.currentProject.currency
                      )}
                      competitor={formatCurrency(
                        state.result.competitorLifetimeTotalCostPerBox,
                        state.currentProject.currency
                      )}
                    />
                  ),
                },
              ]}
            />
          </div>

          <div className="footnote">
            <p>*Lifetime is 12 years.</p>
          </div>

          <Button
            type="basic"
            onClick={() => {
              dispatch('TOGGLE_DOWNLOAD_WINDOW');
            }}
          >
            Download
            <ArrowDownToLine iconTitle="download-total-roi-calculation" />
          </Button>
        </div>
      </div>
    </Panel>
  );
}

export default TotalCalculation;
