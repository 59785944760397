import React from 'react';

import ChevronDown from 'icons/ChevronDown';
import './SelectBox.scss';

interface SelectBoxProps {
  className?: string;
  label?: string;
  name: string;
  onChange: (value: string, id?: number) => void;
  options: string[];
  placeholder?: string;
  value: string;
}

/**
 * Custom styled select box. Passes back the newly selected value when it changes.
 */
function SelectBox(props: SelectBoxProps) {
  return props.label ? (
    <label
      className={`selectbox-wrapper label typo-form-header ${
        props.className ? props.className : ''
      }`}
    >
      {props.label}
      <div className="selectbox-inner">
        <select
          className="selectbox"
          name={props.name}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          value={props.value}
        >
          {props.placeholder && <option value="">{props.placeholder}</option>}
          {props.options.map((option: string, index: number) => (
            <option key={index}>{option}</option>
          ))}
        </select>
        <ChevronDown iconTitle="selectbox-chevron-down" />
      </div>
    </label>
  ) : (
    <div
      className={`selectbox-wrapper ${props.className ? props.className : ''}`}
    >
      <div className="selectbox-inner">
        <select
          className="selectbox"
          name={props.name}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          value={props.value}
        >
          {props.placeholder && <option value="">{props.placeholder}</option>}
          {props.options.map((option: string, index: number) => (
            <option key={index}>{option}</option>
          ))}
        </select>
        <ChevronDown iconTitle="selectbox-chevron-down" />
      </div>
    </div>
  );
}

export default SelectBox;
