import React from 'react';

import './Footnote.scss';

interface FootnoteProps {
  note: string;
}

/**
 * Outputs a line of styled copy, tightening up the spacing and lining up
 * with the start of any form inputs.
 */
function Footnote(props: FootnoteProps) {
  return <div className="footnote">{props.note}</div>;
}

export default Footnote;
