import ProjectPanel from 'features/ProjectPanel/ProjectPanel';

import './Header.scss';

import logoImage from 'images/logo.png';

/**
 * Header area of the app.
 */
function Header() {
  return (
    <div className="header">
      <div className="logo">
        <a
          href="https://www.stirlingultracold.com"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logoImage} alt="Stirling Ultracold Logo" />
        </a>
      </div>
      <ProjectPanel />
    </div>
  );
}

export default Header;
