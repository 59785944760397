import { ModelListItem } from 'const';
import { initStore } from './store';

/**
 * Creates the store for managing state shared across the app.
 */
const configureGlobalStore = () => {
  const actions = {
    // Store the fetched freezer ULT models.
    ADD_FREEZER_MODELS: (state: any, models: ModelListItem[]) => ({
      freezerModels: models,
    }),

    // Store the fetched competitor ULT models.
    ADD_COMPETITOR_MODELS: (state: any, models: ModelListItem[]) => ({
      competitorModels: models,
    }),

    // Sets the currently active panel to the given panel ID.
    SET_ACTIVE_PANEL: (state: any, panel: string) => ({
      activePanel: panel,
    }),

    // Flip the rename project modal state.
    TOGGLE_RENAME_MODAL: (state: any) => ({
      projectPanelVisible: !state.projectPanelVisible,
      renameModalActive: !state.renameModalActive,
    }),

    // Flip the save project modal state.
    TOGGLE_SAVE_MODAL: (state: any) => ({
      saveModalActive: !state.saveModalActive,
    }),

    // Flip the report download window state.
    TOGGLE_DOWNLOAD_WINDOW: (state: any) => ({
      downloadVisible: !state.downloadVisible,
    }),

    // Show the loading screen and spinner.
    ACTIVATE_LOADING_SCREEN: (state: any) => ({
      loadingScreenActive: true,
    }),

    // Hide the loading screen and spinner.
    DEACTIVATE_LOADING_SCREEN: (state: any) => ({
      loadingScreenActive: false,
    }),

    // Sets the calculation type to one of noted options.
    SET_CALCULATION_TYPE: (state: any, type: 'single' | 'multiple') => ({
      calculationType: type,
    }),
  };

  initStore(actions, {
    activePanel: 'home',
    competitorModels: [],
    downloadVisible: false,
    freezerModels: [],
    loadingScreenActive: true,
    overlayVisible: false,
    renameModalActive: false,
    saveModalActive: false,
    calculationType: '',
  });
};

export default configureGlobalStore;
