import React from 'react';

import './TotalCalculationRow.scss';

interface TotalCalculationRowProps {
  competitor: string;
  description: string;
  freezer: string;
  title?: boolean;
}

/**
 * Outputs the row description and data points.
 */
function TotalCalculationRow(props: TotalCalculationRowProps) {
  return (
    <div className={`row ${props.title ? 'title' : ''}`}>
      <div>
        {props.title ? <h5>{props.description}</h5> : props.description}
      </div>
      <div>{props.freezer}</div>
      <div>{props.competitor}</div>
    </div>
  );
}

export default TotalCalculationRow;
