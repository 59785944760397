/**
 * Return a string representing the given number as currency.
 * Optionally pass the number of decimals desired, rounding off any dropped decimals.
 * @param  {number}     num      Number to format.
 * @param  {string}     currency String code of the currency to format to.
 * @param  {number = 2} decimals Number of decimals to output.
 * @return {string} String with the currency formatted number.
 */
export function formatCurrency(
  num: number,
  currency: string,
  decimals: number = 2
): string {
  // Defaults to USD if empty string is sent.
  currency = currency ? currency : 'USD';

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(num);
}

/**
 * Return a string representing the given number as currency, with the symbol wrapped
 * in a <span>.
 * Optionally pass the number of decimals desired, rounding off any dropped decimals.
 * TODO may need to support scenarios where the currency symbol appears after the number.
 * @param  {number}     num      Number to format.
 * @param  {string}     currency String code of the currency to format to.
 * @param  {number = 2} decimals Number of decimals to output.
 * @return {string} String with the currency formatted number, symbol wrapped in <span>.
 */
export function formatCurrencyWrapSymbol(
  num: number,
  currency: string,
  decimals: number = 2
): string {
  // Defaults to USD if empty string is sent.
  currency = currency ? currency : 'USD';

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
    .format(num)
    .replace(/^(\D*)(.*)/, '<span>$1</span>$2');
}

/**
 * Return a string representing the given number in kilograms or pounds, depending
 * on the unit parameter.
 * @param  {number} num  Number to format, in kilograms.
 * @param  {string} unit Measurement unit, "Metric" or "Imperial"
 * @return {string} String with the regionally formatted number in the given unit.
 */
export function formatKilograms(
  num: number,
  unit: 'Metric' | 'Imperial'
): string {
  if (unit === 'Metric') {
    return (
      Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(num) +
      ' kg'
    );
  }

  const convertedNum = num * 2.20462;

  return (
    Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(
      convertedNum
    ) + ' lb'
  );
}

/**
 * Return a string representing the given number per regional requirements.
 * eg. 10000 would return 10,000.
 * Optionally pass the number of decimals desired, rounding off any dropped decimals.
 * @param  {number}     num      Number to format.
 * @param  {number = 2} decimals Number of decimals to output.
 * @return {string} String with the regionally formatted number.
 */
export function formatNumber(num: number, decimals: number = 2): string {
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(num);
}

/**
 * Return a string representing the given number in square meters or square feet,
 * depending on the unit parameter.
 * @param  {number} num  Number to format, in square meters.
 * @param  {string} unit unit Measurement unit, "Metric" or "Imperial"
 * @return {string} String with the regionally formatted number in the given unit.
 */
export function formatSquareMeters(
  num: number,
  unit: 'Metric' | 'Imperial'
): string {
  if (unit === 'Metric') {
    return (
      Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num) + ' m&sup2;'
    );
  }

  const convertedNum = num * 10.7639;

  return (
    Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(convertedNum) + ' SqFt'
  );
}
