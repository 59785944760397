import React, { useState } from 'react';

import ModalScreen from 'components/ModalScreen/ModalScreen';
import './WidthWarning.scss';

/**
 * Basic warning modal for small screen sizes.
 */
function WidthWarning() {
  const [visible, setVisible] = useState(true);

  return (
    <div className={`width-warning ${visible ? 'is-active' : ''}`}>
      <ModalScreen
        heading="Warning"
        visible={visible}
        close={() => {
          setVisible(false);
        }}
      >
        This app is designed for desktop and laptop screen sizes. Smaller
        screens will provide an unoptimized experience.
      </ModalScreen>
    </div>
  );
}

export default WidthWarning;
