import React, { useState, ReactNode } from 'react';

import ChevronDown from 'icons/ChevronDown';
import './TotalCalculationDetails.scss';

interface TotalCalculationDetailsProps {
  description: string;
  freezer: string;
  rows: {
    content: ReactNode;
  }[];
}

/**
 * Outputs a header row, followed by any number of nested rows.
 * Clicking the header row will show/hide the nested rows.
 */
function TotalCalculationDetails(props: TotalCalculationDetailsProps) {
  const [detailsVisible, setDetailsVisible] = useState(false);

  // Toggle the detail rows open and close.
  function toggleDetails() {
    setDetailsVisible(!detailsVisible);
  }

  return (
    <div className={`detail-section ${detailsVisible ? 'is-active' : ''}`}>
      <div className="row title" onClick={toggleDetails}>
        <div>
          <ChevronDown iconTitle="toggle-section-details" />
          <h5>{props.description}</h5>
        </div>
        <div>
          <h4
            dangerouslySetInnerHTML={{
              __html: props.freezer,
            }}
          ></h4>
        </div>
        <div></div>
      </div>

      <div className="details">
        <div className="inner">
          {props.rows.map((row, index) => (
            <div key={index}>{row.content}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TotalCalculationDetails;
