import Button from 'components/Button/Button';
import TrashCan from 'icons/TrashCan';

import './ProjectListRow.scss';

interface ProjectListProps {
  title: string;
  onClickRename: () => void;
  onClickLoad: () => void;
  onClickDelete: () => void;
}

/**
 * Row for the Project Panel dropdown.
 * Lists the project name with rename, load, and delete buttons.
 */
function ProjectListRow(props: ProjectListProps) {
  return (
    <div className="project-row">
      <h4>{props.title}</h4>
      <div className="buttons">
        <Button onClick={props.onClickRename} type="small">
          Rename
        </Button>
        <Button onClick={props.onClickLoad} type="small">
          Load Project
        </Button>
        <Button icon type="small" onClick={props.onClickDelete}>
          <TrashCan iconTitle="trash-can-icon" />
        </Button>
      </div>
    </div>
  );
}

export default ProjectListRow;
