import React, { PropsWithChildren } from 'react';
import { useStore } from 'store/store';
import Scrollbars from 'react-custom-scrollbars-2';
import './Panel.scss';

interface PanelProps {
  id: string;
}

/**
 * Wrapper panel for features or components that will appear
 * in the Main container.
 * Panels are activated by updating the active panel in global
 * store. ID must be unique across all panels.
 */
function Panel(props: PropsWithChildren<PanelProps>) {
  const state = useStore()[0];

  return (
    <div
      className={`panel ${state.activePanel === props.id ? 'is-active' : ''}`}
    >
      <Scrollbars className="panel-scrollbars" autoHide>
        <div className="panel-content">{props.children}</div>
      </Scrollbars>
    </div>
  );
}

export default Panel;
