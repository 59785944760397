import React, { ReactNode } from 'react';

import './ResultsSummary.scss';

interface ResultsSummaryProps {
  copy: string;
  ctas: ReactNode[];
  multi: boolean;
  savings: string;
}

/**
 * Outputs the lifetime cost savings summary and related copy. Includes calls to
 * action capabilities as well (usually will be passing in an array of Buttons).
 * TODO: Maybe? toLocalString() may need a different region
 * depending on the user's selected currency.
 */
function ResultsSummary(props: ResultsSummaryProps) {
  return (
    <div className="results-summary">
      <h6>Lifetime Total Savings*</h6>
      <div className="savings">
        <div
          className="value"
          dangerouslySetInnerHTML={{
            __html: props.savings,
          }}
        ></div>
        <p>{props.copy}</p>
      </div>
      <div className="buttons">
        {props.ctas.map((cta, index) => (
          <div key={index}>{cta}</div>
        ))}
      </div>
      <div className="footnote">*Lifetime is 12 years.</div>
    </div>
  );
}

export default ResultsSummary;
