import React from 'react';
import { useStore } from 'store/store';
import {
  formatCurrency,
  formatCurrencyWrapSymbol,
  formatKilograms,
  formatNumber,
  formatSquareMeters,
} from 'utility';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Button from 'components/Button/Button';
import Footer from 'components/Footer/Footer';
import FreezerGraphic from 'components/FreezerGraphic/FreezerGraphic';
import Panel from 'components/Panel/Panel';
import ResultsSummary from 'components/ResultsSummary/ResultsSummary';
import SavingsPanel from 'components/SavingsPanel/SavingsPanel';
import TabbedPanel from 'features/TabbedPanel/TabbedPanel';
import './SingleResults.scss';

/**
 * Top level Panel that outputs the initial results for the 1:1
 * comparison feature.
 */
function SingleResults() {
  const [state, dispatch] = useStore();

  return (
    <Panel id="single-results">
      <div className="single-results">
        <Breadcrumbs
          crumbs={[
            {
              onClick: () => {
                dispatch('SET_ACTIVE_PANEL', 'home');
              },
              title: 'Home',
            },
            {
              onClick: () => {
                dispatch('SET_ACTIVE_PANEL', 'single-comparison');
              },
              title: '1:1 Comparison Unit',
            },
            {
              title: 'Compare the Difference',
            },
          ]}
        />

        <FreezerGraphic title="Compare the Difference" />

        <div className="right">
          <TabbedPanel
            tabs={[
              {
                title: 'Energy Savings',
                content: (
                  <SavingsPanel
                    copy="Annual operational cost savings between the Stirling model and the competitive model selected."
                    savings={formatCurrencyWrapSymbol(
                      state.result.competitorAnnualEnergyCost -
                        state.result.freezerAnnualEnergyCost,
                      state.currentProject.currency,
                      0
                    )}
                    freezerModel={state.currentProject.freezer}
                    competitorModel={state.currentProject.competitor}
                    data={[
                      {
                        description: 'Number of freezers needed',
                        freezer: formatNumber(state.result.freezersNeeded, 0),
                        competitor: formatNumber(
                          state.result.competitorsNeeded,
                          0
                        ),
                      },
                      {
                        description: 'Energy cost per kWh',
                        freezer: formatCurrency(
                          state.result.energyCost,
                          state.currentProject.currency
                        ),
                        competitor: formatCurrency(
                          state.result.energyCost,
                          state.currentProject.currency
                        ),
                      },
                      {
                        description: 'Lifetime energy cost per L',
                        freezer: formatCurrency(
                          state.result.freezerLifetimeEnergyCostPerLiter,
                          state.currentProject.currency
                        ),
                        competitor: formatCurrency(
                          state.result.competitorLifetimeEnergyCostPerLiter,
                          state.currentProject.currency
                        ),
                      },
                      {
                        description: 'Lifetime energy cost per CuFt',
                        freezer: formatCurrency(
                          state.result.freezerLifetimeEnergyCostPerFoot,
                          state.currentProject.currency
                        ),
                        competitor: formatCurrency(
                          state.result.competitorLifetimeEnergyCostPerFoot,
                          state.currentProject.currency
                        ),
                      },
                      {
                        description: 'Lifetime energy cost per 2" box',
                        freezer: formatCurrency(
                          state.result.freezerLifetimeEnergyCostPerBox,
                          state.currentProject.currency
                        ),
                        competitor: formatCurrency(
                          state.result.competitorLifetimeEnergyCostPerBox,
                          state.currentProject.currency
                        ),
                      },
                    ]}
                  />
                ),
              },
              ...(state.currentProject.competitorCost
                ? [
                    {
                      title: 'Equipment Costs',
                      content: (
                        <SavingsPanel
                          copy="Capital investment difference based on averaged equipment prices for the number of freezers selected."
                          savings={formatCurrencyWrapSymbol(
                            state.result.competitorLifetimeEquipmentCost -
                              state.result.freezerLifetimeEquipmentCost,
                            state.currentProject.currency,
                            0
                          )}
                          freezerModel={state.currentProject.freezer}
                          competitorModel={state.currentProject.competitor}
                          data={[
                            {
                              description: 'Number of freezers needed',
                              freezer: formatNumber(
                                state.result.freezersNeeded,
                                0
                              ),
                              competitor: formatNumber(
                                state.result.competitorsNeeded,
                                0
                              ),
                            },
                            {
                              description: 'Lifetime equip. cost per L',
                              freezer: formatCurrency(
                                state.result
                                  .freezerLifetimeEquipmentCostPerLiter,
                                state.currentProject.currency
                              ),
                              competitor: formatCurrency(
                                state.result
                                  .competitorLifetimeEquipmentCostPerLiter,
                                state.currentProject.currency
                              ),
                            },
                            {
                              description: 'Lifetime equip. cost per CuFt',
                              freezer: formatCurrency(
                                state.result
                                  .freezerLifetimeEquipmentCostPerFoot,
                                state.currentProject.currency
                              ),
                              competitor: formatCurrency(
                                state.result
                                  .competitorLifetimeEquipmentCostPerFoot,
                                state.currentProject.currency
                              ),
                            },
                            {
                              description: 'Lifetime equip. cost per 2" box',
                              freezer: formatCurrency(
                                state.result.freezerLifetimeEquipmentCostPerBox,
                                state.currentProject.currency
                              ),
                              competitor: formatCurrency(
                                state.result
                                  .competitorLifetimeEquipmentCostPerBox,
                                state.currentProject.currency
                              ),
                            },
                          ]}
                        />
                      ),
                    },
                  ]
                : []),
              {
                title: 'Carbon Reduction',
                content: (
                  <SavingsPanel
                    copy="Carbon reduction opportunity with Stirling model, improving ESG ratings and minimizing impact on climate change."
                    savings={formatKilograms(
                      state.result.competitorLifetimeCarbonOutput -
                        state.result.freezerLifetimeCarbonOutput,
                      state.currentProject.measureUnits
                    )}
                    freezerModel={state.currentProject.freezer}
                    competitorModel={state.currentProject.competitor}
                    data={[
                      {
                        description: 'Number of freezers needed',
                        freezer: formatNumber(state.result.freezersNeeded, 0),
                        competitor: formatNumber(
                          state.result.competitorsNeeded,
                          0
                        ),
                      },
                      {
                        description: 'Carbon output per kWh',
                        freezer: state.result.carbonCost,
                        competitor: state.result.carbonCost,
                      },
                      {
                        description: 'Lifetime carbon output per L',
                        freezer: `${formatKilograms(
                          state.result.freezerLifetimeCarbonOutputPerLiter,
                          state.currentProject.measureUnits
                        )}`,
                        competitor: `${formatKilograms(
                          state.result.competitorLifetimeCarbonOutputPerLiter,
                          state.currentProject.measureUnits
                        )}`,
                      },
                      {
                        description: 'Lifetime carbon output per CuFt',
                        freezer: `${formatKilograms(
                          state.result.freezerLifetimeCarbonOutputPerFoot,
                          state.currentProject.measureUnits
                        )}`,
                        competitor: `${formatKilograms(
                          state.result.competitorLifetimeCarbonOutputPerFoot,
                          state.currentProject.measureUnits
                        )}`,
                      },
                      {
                        description: 'Lifetime carbon output per 2" box',
                        freezer: `${formatKilograms(
                          state.result.freezerLifetimeCarbonOutputPerBox,
                          state.currentProject.measureUnits
                        )}`,
                        competitor: `${formatKilograms(
                          state.result.competitorLifetimeCarbonOutputPerBox,
                          state.currentProject.measureUnits
                        )}`,
                      },
                    ]}
                  />
                ),
              },
              {
                title: 'Space Savings',
                content: (
                  <SavingsPanel
                    copy="Floorspace savings available, without comprising storage volumes, using the Stirling model selected. "
                    savings={formatSquareMeters(
                      state.result.competitorTotalFloorSpace -
                        state.result.freezerTotalFloorSpace,
                      state.currentProject.measureUnits
                    )}
                    freezerModel={state.currentProject.freezer}
                    competitorModel={state.currentProject.competitor}
                    data={[
                      {
                        description: 'Number of freezers needed',
                        freezer: formatNumber(state.result.freezersNeeded, 0),
                        competitor: formatNumber(
                          state.result.competitorsNeeded,
                          0
                        ),
                      },
                      {
                        description: 'Total floor space required (m2)',
                        freezer: formatNumber(
                          state.result.freezerTotalFloorSpace
                        ),
                        competitor: formatNumber(
                          state.result.competitorTotalFloorSpace
                        ),
                        floorSpaceComparison: true,
                        floorSpaceCopy:
                          'This is a visual comparison of the space savings (square meters) available when selecting a Stirling model (dark blue) versus the competitive model (light blue).',
                        floorSpaceUnits: 'm&sup2;',
                      },
                      {
                        description: 'Total floor space required (SqFt)',
                        freezer: formatNumber(
                          state.result.freezerTotalFloorSpaceFeet
                        ),
                        competitor: formatNumber(
                          state.result.competitorTotalFloorSpaceFeet
                        ),
                        floorSpaceComparison: true,
                        floorSpaceCopy:
                          'This is a visual comparison of the space savings (square feet) available when selecting a Stirling model (dark blue) versus the competitive model (light blue).',
                        floorSpaceUnits: 'SqFt',
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
          <ResultsSummary
            multi={false}
            copy="will be saved over a 12-year lifetime. Please note, this 12-year lifetime is not promised with many competitive units, but these calculations are conservative. "
            savings={formatCurrencyWrapSymbol(
              state.result.competitorLifetimeTotalCost -
                state.result.freezerLifetimeTotalCost,
              state.currentProject.currency,
              0
            )}
            ctas={[
              <Button
                type="basic"
                onClick={() => {
                  dispatch('SET_ACTIVE_PANEL', 'total-calculation');
                }}
              >
                View total Savings data
              </Button>,
              <Button
                type="border"
                onClick={() => {
                  dispatch('TOGGLE_DOWNLOAD_WINDOW');
                }}
              >
                Download Savings data
              </Button>,
            ]}
          />
        </div>

        <Footer />
      </div>
    </Panel>
  );
}

export default SingleResults;
