import React, { useState, Fragment } from 'react';

import ChevronRight from 'icons/ChevronRight';
import './SavingsPanel.scss';

interface SavingsPanelProps {
  competitorModel: string;
  copy: string;
  data: {
    description: string;
    freezer: string;
    competitor: string;
    floorSpaceComparison?: boolean;
    floorSpaceCopy?: string;
    floorSpaceUnits?: any;
  }[];
  freezerModel: string;
  savings: string;
}

/**
 * Outputs the given datapoints, copy, and final savings number.
 * TODO: Maybe? toLocalString() may need a different region
 * depending on the user's selected currency.
 */
function SavingsPanel(props: SavingsPanelProps) {
  const [dropdown, setDropdown] = useState(
    new Array(props.data.length).fill(false)
  );

  /**
   * User clicked a dropdown row, so open or close the dropdown with the given index.
   */
  function handleClick(i: number) {
    const newDropdown = dropdown.map((item, index) => {
      if (index === i) {
        return !item;
      } else {
        return item;
      }
    });

    setDropdown(newDropdown);
  }

  return (
    <div className="savings-panel">
      <div className="savings">
        <div
          className="value"
          dangerouslySetInnerHTML={{
            __html: props.savings,
          }}
        ></div>
        <p>{props.copy}</p>
      </div>
      <div className="quasitable">
        <div className="row">
          <div className="cell">&nbsp;</div>
          <div className="cell heading big">{props.freezerModel}</div>
          <div className="cell heading big">{props.competitorModel}</div>
        </div>
        {props.data.map((item, index) => {
          if (item.floorSpaceComparison) {
            const pixelsPerUnit = 200 / parseInt(item.competitor);
            let innerPixels = parseInt(item.freezer) * pixelsPerUnit;
            if (innerPixels < 90) innerPixels = 90;
            else if (innerPixels > 150) innerPixels = 150;

            return (
              <Fragment key={index}>
                <div
                  className={`row floor-space-comparison ${
                    dropdown[index] ? 'is-active' : ''
                  }`}
                  onClick={() => {
                    handleClick(index);
                  }}
                >
                  <div className="cell heading small">{item.description}</div>
                  <div className="cell body">{item.freezer}</div>
                  <div className="cell body">{item.competitor}</div>
                  <ChevronRight iconTitle="dropdown-chevron-icon" />
                </div>
                <div className="comparison-panel">
                  <div className="container">
                    <div className="comparison">
                      <div className="outer">
                        {item.competitor}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.floorSpaceUnits,
                          }}
                        />
                      </div>
                      <div
                        className="inner"
                        style={{ width: innerPixels, height: innerPixels }}
                      >
                        {item.freezer}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.floorSpaceUnits,
                          }}
                        />
                      </div>
                    </div>
                    <div className="comparison-copy">
                      <p>{item.floorSpaceCopy}</p>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          } else {
            return (
              <div key={index} className="row">
                <div className="cell heading small">{item.description}</div>
                <div className="cell body">{item.freezer}</div>
                <div className="cell body">{item.competitor}</div>
              </div>
            );
          }
        })}
        <div className="row">
          <div className="cell"></div>
          <div className="cell"></div>
          <div className="cell"></div>
        </div>
      </div>
    </div>
  );
}

export default SavingsPanel;
