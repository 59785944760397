import React, { ReactNode, useState } from 'react';

import './TabbedPanel.scss';

interface TabbedPanelProps {
  tabs: {
    title: string;
    content: ReactNode;
  }[];
}

/**
 * Outputs the tabbed container, wrapping up the given content.
 * Manages the state of the container (eg. which tab is active).
 * Content can be any React component or standard HTML.
 */
function TabbedPanel(props: TabbedPanelProps) {
  const [activeTab, setActiveTab] = useState(0);

  /**
   * If needed, set the new active tab when the user clicks on a
   * tab.
   */
  function handleTabClick(newTab: number) {
    if (newTab === activeTab) return;

    setActiveTab(newTab);
  }

  return (
    <div className="tabbed-panel">
      <div className="tabs">
        {props.tabs.map((tab, index) => (
          <button
            key={index}
            className={`${index === activeTab ? 'is-active' : ''}`}
            onClick={() => {
              handleTabClick(index);
            }}
          >
            <span>{tab.title}</span>
          </button>
        ))}
      </div>
      <div className="content">
        {props.tabs.map((tab, index) => (
          <div
            key={index}
            className={`content-panel ${
              index === activeTab ? 'is-active' : ''
            }`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TabbedPanel;
