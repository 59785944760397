import React from 'react';

import './OnOff.scss';

interface OnOffProps {
  description: string;
  label?: string;
  name: string;
  onChange: (value: boolean) => void;
  value: boolean;
}

/**
 * Custom styled "On-Off" box, which is effectively a styled checkbox. Passes back
 * the newly selected boolean value when it changes.
 * - State is managed globally through the value prop.
 */
function OnOff(props: OnOffProps) {
  /**
   * Update the on/off state.
   */
  function handleChange() {
    props.onChange(!props.value);
  }

  return (
    <div className="onoff">
      {props.label ? (
        <div className="heading typo-form-header">{props.label}</div>
      ) : (
        ''
      )}
      <label>
        <div className={`widget ${props.value ? 'checked' : ''}`}>
          <input
            type="checkbox"
            name={props.name}
            checked={props.value}
            onChange={handleChange}
          />
          <span></span>
        </div>
        {props.description}
      </label>
    </div>
  );
}

export default OnOff;
