import { useStore } from 'store/store';

import CaretDown from 'icons/CaretDown';
import CloseButton from 'components/Close/CloseButton';
import MenuButton from 'components/MenuButton/MenuButton';
import ProjectListRow from 'components/ProjectListRow/ProjectListRow';
import RenameProject from 'components/RenameProjectModal/RenameProjectModal';
import SaveProject from 'components/SaveProjectModal/SaveProjectModal';
import './ProjectPanel.scss';

/**
 * Dropdown project menu that is triggered from the Heading.
 */
function ProjectPanel() {
  const [state, dispatch] = useStore();

  /**
   * Start a new project.
   */
  function newProject() {
    dispatch('SET_ACTIVE_PANEL', 'home');
    dispatch('START_A_NEW_PROJECT');
  }

  /**
   * Open or close the project panel dropdown.
   */
  function toggleProjectPanel() {
    dispatch('TOGGLE_PROJECT_PANEL');
  }

  /**
   * Toggle the Rename Project modal. Mostly used to close it.
   */
  function toggleRenameModal() {
    dispatch('TOGGLE_RENAME_MODAL');
  }

  /**
   * Toggle the Save Project modal. Mostly used to close it.
   */
  function toggleSaveModal() {
    dispatch('TOGGLE_SAVE_MODAL');
  }

  return (
    <>
      <div className="project-panel">
        <div className="menu">
          <div className="item">
            <MenuButton onClick={newProject}>Start a New Project</MenuButton>
          </div>

          {state.projects.length > 0 ? (
            <div
              className={`item ${state.projectPanelVisible ? 'is-active' : ''}`}
            >
              <MenuButton menu onClick={toggleProjectPanel}>
                My Projects
                {state.projectName !== '' ? (
                  <span className="current-project">
                    <span className="divider">/</span>
                    <span className="project-name">{state.projectName}</span>
                  </span>
                ) : (
                  ''
                )}
                <CaretDown iconTitle="caret-down-icon" />
              </MenuButton>

              <div className="projects">
                <div className="inner">
                  <CloseButton onClick={toggleProjectPanel} />
                  {state.projectName ? (
                    <span className="currently-viewing">
                      <h3>Currently Viewing:</h3>
                      <ProjectListRow
                        title={state.projectName}
                        onClickRename={() => {
                          dispatch(
                            'SET_PROJECT_RENAME_INFO',
                            state.projectName
                          );
                          dispatch('TOGGLE_RENAME_MODAL');
                        }}
                        onClickLoad={() => {
                          dispatch('LOAD_PROJECT', state.projectName);
                        }}
                        onClickDelete={() => {
                          dispatch('REMOVE_PROJECT', state.projectName);
                        }}
                      />
                    </span>
                  ) : (
                    ''
                  )}

                  {state.projects.length > 0 ? (
                    <span className="previously-saved">
                      <h3>Previously Saved:</h3>
                      {state.projects.map((project: any, index: number) => (
                        <ProjectListRow
                          key={index}
                          title={project.name}
                          onClickRename={() => {
                            dispatch('SET_PROJECT_RENAME_INFO', project.name);
                            dispatch('TOGGLE_RENAME_MODAL');
                          }}
                          onClickLoad={() => {
                            dispatch('LOAD_PROJECT', project.name);
                          }}
                          onClickDelete={() => {
                            dispatch('REMOVE_PROJECT', project.name);
                          }}
                        />
                      ))}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <RenameProject
        close={toggleRenameModal}
        visible={state.renameModalActive}
      />
      <SaveProject close={toggleSaveModal} visible={state.saveModalActive} />
    </>
  );
}

export default ProjectPanel;
