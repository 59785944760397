import React from 'react';

import './Footer.scss';

interface FooterProps {
  color?: 'white';
}

/**
 * Footer area of the app.
 */
function Footer(props: FooterProps) {
  return (
    <div className={`footer ${props.color ? props.color : ''}`}>
      &copy; Copyright 2023 | Stirling Ultracold
    </div>
  );
}

export default Footer;
