import React from 'react';

import './CloseButton.scss';
import XMarkIcon from 'icons/XMarkIcon';

interface CloseButtonProps {
  onClick: () => void;
}

/**
 * Outputs the stylized close button/icon and executes the given function on click.
 */
function CloseButton(props: CloseButtonProps) {
  return (
    <div className="close-button" onClick={props.onClick}>
      <XMarkIcon iconTitle="modal-close" />
    </div>
  );
}

export default CloseButton;
