import { useStore } from 'store/store';

import './Overlay.scss';

interface OverlayProps {
  visible: boolean;
}

/**
 * Overlay used to dim the background.
 * Used with the Project Panel, Save Project and Rename Project modals.
 * - Overlay is at the $z3 index level.
 * - Modal content is at $z4 index level.
 * - Project Panel will pull the overlay down to the $z2 level to avoid layering
 *   issues when switching between the rename modal.
 */
function Overlay(props: OverlayProps) {
  const state = useStore()[0];

  return (
    <div
      className={`overlay ${props.visible ? 'is-active' : ''} ${
        state.projectPanelVisible ? 'project-panel' : ''
      }`}
    ></div>
  );
}

export default Overlay;
