import React from 'react';
import { ModelListItem } from 'const';
import { useStore } from 'store/store';

import Button from 'components/Button/Button';
import Footer from 'components/Footer/Footer';
import OrLine from 'components/OrLine/OrLine';
import Panel from 'components/Panel/Panel';
import SelectBox from 'components/Forms/SelectBox/SelectBox';
import './Home.scss';

import backgroundVideo from 'videos/Stirling-ROI-Home-Animation.mp4';

/**
 * Home screen of the app.
 */
function Home() {
  const [state, dispatch] = useStore();

  // Clicked compare multiple freezers button.
  function clickMultiple() {
    dispatch('SET_ACTIVE_PANEL', 'multi-comparison');
  }

  // Takes the user to the 1:1 comparison panel.
  function clickSingle() {
    dispatch('SET_ACTIVE_PANEL', 'single-comparison');
  }

  // User changed value of the competitor select box.
  function handleCompetitorChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'competitor', value: val });
  }

  // User changed value of the freezer select box.
  function handleFreezerChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'freezer', value: val });
  }

  return (
    <Panel id="home">
      <div className="home">
        <video muted loop autoPlay>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <div className="wrapper">
          <div className="content">
            <h3>Stirling ULT</h3>
            <h1>Savings Calculator</h1>
            <p>
              This tool has been developed to help ultra-low temperature freezer
              users compare different makes and models of freezers to support an
              educated buying experience. Whether replacing multiple, dated
              compressor-based freezers or looking to add a single unit to their
              fleet, this tool will help guide their decision.
            </p>
          </div>
          <div className="form">
            <div className="fifty-fifty">
              <SelectBox
                label="Stirling ULT"
                name="freezer"
                placeholder="Select model ..."
                options={state.freezerModels.map(
                  (freezer: ModelListItem) => freezer.model
                )}
                onChange={handleFreezerChange}
                value={state.currentProject.freezer}
              />
              <SelectBox
                label="ULT you want to compare"
                name="competitor"
                placeholder="Select model ..."
                options={state.competitorModels.map(
                  (competitor: ModelListItem) => competitor.model
                )}
                onChange={handleCompetitorChange}
                value={state.currentProject.competitor}
              />
            </div>
            <Button type="basic" wide={true} onClick={clickSingle}>
              Let's compare
            </Button>
            <OrLine />
            <Button type="border" wide={true} onClick={clickMultiple}>
              Replace multiple freezer models
            </Button>
          </div>
        </div>

        <Footer color="white" />
      </div>
    </Panel>
  );
}

export default Home;
