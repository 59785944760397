import React, { useEffect, useRef, useState } from 'react';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import { useStore } from 'store/store';
import {
  formatCurrency,
  formatCurrencyWrapSymbol,
  formatNumber,
} from 'utility';

import FreezerGraphic from 'components/FreezerGraphic/FreezerGraphic';
import SavingsPanel from 'components/SavingsPanel/SavingsPanel';
import WeKnowCold from 'icons/WeKnowCold';
import './ReportDownload.scss';

import logoImage from 'images/logo.png';

/**
 * Report Download screen.
 * Displays above all of the panels and will trigger an automatic download.
 */
function ReportDownload() {
  const [state, dispatch] = useStore();
  const downloadWindow = useRef(null);
  // Back link text visibility.
  const [backLinkVisible, setBackLinkVisible] = useState(false);
  const takeScreenShot = useScreenshot({
    type: 'image/jpeg',
    quality: 0.9,
  })[1];

  /**
   * Watch for the  download window being visible and start the download.
   */
  useEffect(() => {
    if (state.downloadVisible === true && backLinkVisible === false) {
      // Delay to be sure the window has loaded.
      setTimeout(() => {
        const download = (
          image: any,
          { name = 'Stirling Ultracold ROI', extension = 'jpg' } = {}
        ) => {
          // Set filename.
          if (state.projectName !== '') {
            name = 'Stirling Ultracold - ' + state.projectName;
          }
          // Download.
          const a = document.createElement('a');
          a.href = image;
          a.download = createFileName(extension, name);
          a.click();
        };

        // Scroll to top so the screenshot does not get cutoff.
        const div = document.getElementById('downloadWindow');
        if (div) {
          div.scrollTo({
            top: 0,
            left: 0,
          });
        }

        takeScreenShot(downloadWindow.current)
          .then(download)
          .then(setBackLinkVisible(true));
      }, 1000);
    }
  }, [
    backLinkVisible,
    state.downloadVisible,
    state.projectName,
    takeScreenShot,
  ]);

  /**
   * Close the download window.
   */
  function closeDownloadWindow() {
    setBackLinkVisible(false);
    dispatch('TOGGLE_DOWNLOAD_WINDOW');
  }

  return (
    <div
      ref={downloadWindow}
      id="downloadWindow"
      className={`report-download ${state.downloadVisible ? 'is-active' : ''}`}
    >
      <div className="size">
        <div className="header">
          <div className="logo">
            <img src={logoImage} alt="Stirling Ultracold Logo" />
          </div>
          <div className="tagline">
            <WeKnowCold iconTitle="header-tagline" />
          </div>
        </div>
        <div className="main">
          <FreezerGraphic background={true} />
          <div className="right">
            <h2>Lifetime Energy Savings</h2>
            <div className="content">
              <SavingsPanel
                copy="will be saved over a 12-year lifetime. Please note, this 12-year lifetime is not promised with many competitive units, but these calculations are conservative."
                savings={formatCurrencyWrapSymbol(
                  state.result.competitorLifetimeEnergyCost -
                    state.result.freezerLifetimeEnergyCost,
                  state.currentProject.currency,
                  0
                )}
                freezerModel={state.currentProject.freezer}
                competitorModel={
                  state.calculationType === 'single'
                    ? state.currentProject.competitor
                    : 'Competitors'
                }
                data={[
                  {
                    description: 'Number of freezers needed',
                    freezer: formatNumber(state.result.freezersNeeded, 0),
                    competitor: formatNumber(state.result.competitorsNeeded, 0),
                  },
                  {
                    description: 'Energy cost per kWh',
                    freezer: formatCurrency(
                      state.result.energyCost,
                      state.currentProject.currency
                    ),
                    competitor: formatCurrency(
                      state.result.energyCost,
                      state.currentProject.currency
                    ),
                  },
                  {
                    description: 'Lifetime energy cost per L',
                    freezer: formatCurrency(
                      state.result.freezerLifetimeEnergyCostPerLiter,
                      state.currentProject.currency
                    ),
                    competitor: formatCurrency(
                      state.result.competitorLifetimeEnergyCostPerLiter,
                      state.currentProject.currency
                    ),
                  },
                  {
                    description: 'Lifetime energy cost per CuFt',
                    freezer: formatCurrency(
                      state.result.freezerLifetimeEnergyCostPerFoot,
                      state.currentProject.currency
                    ),
                    competitor: formatCurrency(
                      state.result.competitorLifetimeEnergyCostPerFoot,
                      state.currentProject.currency
                    ),
                  },
                  {
                    description: 'Lifetime energy cost per 2" box',
                    freezer: formatCurrency(
                      state.result.freezerLifetimeEnergyCostPerBox,
                      state.currentProject.currency
                    ),
                    competitor: formatCurrency(
                      state.result.competitorLifetimeEnergyCostPerBox,
                      state.currentProject.currency
                    ),
                  },
                ]}
              />
            </div>
            <div
              className={`preview-text ${backLinkVisible ? 'is-active' : ''}`}
              onClick={closeDownloadWindow}
            >
              <p>
                (This is a preview of the 16:9 download. Click here to go back
                to previous page.)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDownload;
